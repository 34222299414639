<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title mt-4">
              <i class="fa-solid fa-file-pen" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="gpath_aptitudeAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="col-7 col-sm-4 col-md-3 col-lg-3 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
              </b-col>
              <b-col class="col-5 col-sm-3 col-md-2 col-lg-3 mb-2">
                <VueJsonToCsv :json-data="gpath_aptitudeObjList" csv-title="gpath_career_aptitude" v-if="gpath_aptitudeObjList && gpath_aptitudeObjList.length">
                  <b-btn variant="primary" class="Download_button">CSV</b-btn>
                </VueJsonToCsv>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="gpath_aptitudeObjList && gpath_aptitudeObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpath_aptitudeObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(name)="data">
                    <p>{{data.item.name}}</p>
                    <b-button variant="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="gpath_aptitudeEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant="iq-bg-danger actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="showGPathAptitudeDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                  <template v-slot:cell(image)="data">
                    <img :src="data.item.image" style="background-color: #e52e2c; width: 50px; height: 50px;">
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGPathAptitudeAdd"
      scrollable
      :title="cvAddModalHeader">
      <GPathAptitudeAdd :propOpenedInModal="true" @emitCloseGPathAptitudeAddModal="closeGPathAptitudeAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_aptitudeAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathAptitudeAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelGPathAptitudeEdit"
      scrollable
      :title="cvEditModalHeader"
      size="lg"
    >
      <GPathAptitudeEdit :propOpenedInModal="true" :propGPathAptitudeObj="gpath_aptitudeEditObj" @emitCloseGPathAptitudeEditModal="closeGPathAptitudeEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_aptitudeEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathAptitudeEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelGPathAptitudeDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGPathAptitudeDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="gpath_aptitudeDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { GPathAptitudes } from "../../../FackApi/api/GPathAptitude"
import GPathAptitudeAdd from "./GPathAptitudeAdd"
import GPathAptitudeEdit from "./GPathAptitudeEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"

export default {
  name: "GPathAptitudeList",
  components: {
    GPathAptitudeAdd,
    GPathAptitudeEdit,
    CsvDownload,
    CsvUpload
  },
  data () {
    return {
      apiName: "gpath_aptitude_list",
      cvModuleFile: "gpath_aptitude",
      cvModulePrefix: "GPA",
      cvCardTitle: "GPath Aptitude",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GPathAptitude",
      cvAddModalHeader: "Add GPathAptitude",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPathAptitude List Response",
      showModelGPathAptitudeAdd: false,
      showModelGPathAptitudeEdit: false,
      showModelGPathAptitudeDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "image", key: "image", class: "text-left align-text-top", sortable: true },
        { label: "name", key: "name", class: "text-left align-text-top", sortable: true }
      ],
      gpath_aptitudeObjList: null,
      gpath_aptitudeEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    this.gpath_aptitudeList()
  },
  methods: {
    /**
     * Calling Function as per whereFilter
    */
    async getgpath_aptitudeDateWise () {
      this.gpath_aptitudeList()
      await this.setDateFilter()
    },
    /**
     * gpath_aptitudeList
     */
    async gpath_aptitudeList () {
      try {
        let gpathAptitudeListResp = await GPathAptitudes.gpath_aptitudeList(this, this.whereFilter)
        if (gpathAptitudeListResp.resp_status) {
          this.gpath_aptitudeObjList = gpathAptitudeListResp.resp_data.data
          this.totalRows = gpathAptitudeListResp.resp_data.count
        }
        else {
          this.toastMsg = gpathAptitudeListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at gpath_aptitudeList() and Exception:", err.message)
      }
    },
    /**
     * gpath_aptitudeAdd
     */
    gpath_aptitudeAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_aptitude_add")
        }
        else {
          this.showModelGPathAptitudeAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_aptitudeAdd() and Exception:", err.message)
      }
    },
    /**
     * gpath_aptitudeEdit
     */
    gpath_aptitudeEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_aptitude_edit/" + this.gpath_aptitudeEditObj.gpa_id)
        }
        else {
          this.gpath_aptitudeEditObj = item
          this.showModelGPathAptitudeEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_aptitudeEdit() and Exception:", err.message)
      }
    },
    /**
     * showGPathAptitudeDeleteDialog
     */
    showGPathAptitudeDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGPathAptitudeDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGPathAptitudeDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * gpath_aptitudeDelete
     */
    async gpath_aptitudeDelete () {
      try {
        let gpathAptitudeDelResp = await GPathAptitudes.gpath_aptitudeDelete(this, this.delObj.gpa_id)
        await ApiResponse.responseMessageDisplay(this, gpathAptitudeDelResp)

        if (gpathAptitudeDelResp && !gpathAptitudeDelResp) {
          this.showModelGPathAptitudeDelete = false
          return false
        }

        let index = this.gpath_aptitudeObjList.indexOf(this.delObj)
        this.gpath_aptitudeObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGPathAptitudeDelete = false
      }
      catch (err) {
        console.error("Exception occurred at gpath_aptitudeDelete() and Exception:", err.message)
      }
    },
    /**
     * closeGPathAptitudeAddModal
     */
    closeGPathAptitudeAddModal (gpathAptitudeAddData) {
      try {
        if (gpathAptitudeAddData) {
          if (this.gpath_aptitudeObjList && this.gpath_aptitudeObjList.length >= 1) {
            let gpathAptitudeObjLength = this.gpath_aptitudeObjList.length
            let lastId = this.gpath_aptitudeObjList[gpathAptitudeObjLength - 1]["id"]
            gpathAptitudeAddData.id = lastId + 1
          }
          else {
            this.gpath_aptitudeObjList = []
            gpathAptitudeAddData.id = 11111
          }

          gpathAptitudeAddData.created_on = moment()
          this.gpath_aptitudeObjList.unshift(gpathAptitudeAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGPathAptitudeAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegpath_aptitudeAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeGPathAptitudeEditModal
     */
    closeGPathAptitudeEditModal () {
      try {
        this.showModelGPathAptitudeEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGPathAptitudeEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
