<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmGPathAptitudeFormData && Object.keys(vmGPathAptitudeFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationname">
                    {{cvNameLabel}}</label
                  >
                  <input
                    v-model="vmGPathAptitudeFormData.name"
                    type="text"
                    class="form-control"
                    required/>
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationdescription">
                    {{cvDescriptionLabel}}</label
                  >
                  <textarea
                    v-model="vmGPathAptitudeFormData.description"
                    type="text"
                    class="form-control textarea"
                    required></textarea>
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationimage">
                    {{cvImageLabel}}</label
                  >
                  <br>
                  <img :src="vmGPathAptitudeFormData.image" style="background-color: #e52e2c; width: 50px; height: 50px;"/>
                  <input
                    v-model="vmGPathAptitudeFormData.image"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-primary" @click="gpath_aptitudeEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { GPathAptitudes } from "../../../FackApi/api/GPathAptitude"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "GPathAptitudeEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propGPathAptitudeObj: {
      type: Object,
      default: function () {
        return {
          "name": "",
          "description": "",
          "image": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "gpath_aptitude_edit",
      cvCardTitle: "Edit GPaths Aptitude",
      cvCardSubHeader: "Edit GPaths Aptitude ",
      cvSubmitBtn: "Edit",
      cvNameLabel: "name",
      cvDescriptionLabel: "description",
      cvImageLabel: "image",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPathAptitude Edit Response",
      vmGPathAptitudeFormData: {}
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.gpath_aptitudeView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGPathAptitudeFormData) {
          if (!this.vmGPathAptitudeFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * gpath_aptitudeView
     */
    async gpath_aptitudeView () {
      try {
        if (this.propOpenedInModal) {
          this.vmGPathAptitudeFormData = this.propGPathAptitudeObj
        }
        else {
          let gpathAptitudeId = this.$route.params.gpa_id
          let gpathAptitudeViewResp = await GPathAptitudes.gpath_aptitudeView(this, gpathAptitudeId)

          if (gpathAptitudeViewResp && gpathAptitudeViewResp.resp_status) {
            this.vmGPathAptitudeFormData = gpathAptitudeViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_aptitudeView() and Exception:", err.message)
      }
    },
    /**
     * gpath_aptitudeEdit
     */
    async gpath_aptitudeEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let gpathAptitudeAddResp = await GPathAptitudes.gpath_aptitudeEdit(this, this.vmGPathAptitudeFormData)
        await ApiResponse.responseMessageDisplay(this, gpathAptitudeAddResp)

        if (gpathAptitudeAddResp && !gpathAptitudeAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGPathAptitudeEditModal", this.vmGPathAptitudeFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_aptitudeEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
