/*eslint-disable */
// import GPathAptitudeModel from '../../Model/GPathAptitude'
import request from '../../Utils/curl'

let GPathAptitudes = {
  /**
   * gpath_aptitudeList
   */
  async gpath_aptitudeList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_aptitude_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at gpath_aptitudeList() and Exception:',err.message)
    }
  },

  /**
   * gpath_aptitudeView
   */
  async gpath_aptitudeView (context, gpathAptitudeId) {
    try {      
      let post_data = new FormData();
      post_data.append('gpa_id', gpathAptitudeId);
      return await request.curl(context, "gpath_aptitude_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at gpath_aptitudeView() and Exception:",err.message)
    }    
  },

  /**
   * gpath_aptitudeAdd
   */
  async gpath_aptitudeAdd (context, gpath_aptitudeObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_aptitudeObj) {
        post_data.append(key, gpath_aptitudeObj[key]);
      }

      return await request.curl(context, "gpath_aptitude_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_aptitudeAdd() and Exception:',err.message)
    }
  },

  /**
   * gpath_aptitudeEdit
   */
  async gpath_aptitudeEdit (context, gpath_aptitudeObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_aptitudeObj) {
        post_data.append(key, gpath_aptitudeObj[key]);
      }

      return await request.curl(context, "gpath_aptitude_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_aptitudeEdit() and Exception:',err.message)
    }
  },

  /**
   * gpath_aptitudeDelete
   */
  async gpath_aptitudeDelete (context, gpathAptitudeId) {
    try{
      let post_data = new FormData();
      
      post_data.append('gpa_id', gpathAptitudeId);

      return await request.curl(context, "gpath_aptitude_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_aptitudeDelete() and Exception:',err.message)
    }
  }
}

export {
  GPathAptitudes
}
